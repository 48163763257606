<template>
  <div class="container-fluid general-widget">
    <div class="row">
      <h1 style="    margin-top: 100px; margin-bottom: 50px;" class="text-center">Welcome to {{ this.$store.state.auth.user.data.facility.state }} Dashboard select a page to continue</h1>
    </div>
    <div class="row">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  State Dahboard
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  <router-link :to="{ name: 'dashboard' }" target="_blank">
                    <button class="btn btn-primary btn-block">
                      Go to dashboard page
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-home fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  State viralload
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  <router-link :to="{ name: 'Viralload' }" target="_blank">
                    <button class="btn btn-primary btn-block">
                      Go to viralload cascade page
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                 <i class="fa fa-flask fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  State Retention
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      <router-link :to="{ name: 'Retention' }" target="_blank">
                        <button class="btn btn-primary btn-block">
                          Go to retention page
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="icon-bg" data-feather="shopping-bag"></i>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-group fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pending Requests Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  Main Screen 
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  <router-link :to="{ name: 'main' }" target="_blank">
                    <button class="btn btn-primary btn-block">
                      Go to main screen 
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-inbox fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
       <!-- Pending Requests Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  Reception Screen 
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  <router-link :to="'/reception'" target="_blank">
                    <button class="btn btn-primary btn-block">
                      Go to reception screen 
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-inbox fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { h } from "vue";

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    this.targetAchievmentByLGA =
      this.$store.state.auth.user.data.targetAchievmentByLGA;
    return {
      data: this.targetAchievmentByLGA.map(
        (item) =>
          function VueCarouselItem() {
            return h(
              "div",
              {
                class: "row",
              },
              h(
                "div", // type
                { class: "col-lg-6" }, // props

                [
                  h(
                    "h2",
                    {
                      class: "facility-count",
                      style: "border-bottom: 16px solid red",
                    },
                    [`${item.LGA}`]
                  ),
                ]
              ),
              h(
                "div", // type
                { class: "col-lg-3" }, // props

                [
                  h(
                    "h2",
                    {
                      style:
                        "background-color: #97781d !important;  color: #fff; font-weight: 700; display: flex; justify-content: center; font-size: 5rem",
                    },
                    [`${item.tx_new_achieve}`]
                  ),
                ]
              ),
              h(
                "div", // type
                { class: "col-lg-3" }, // props

                [
                  h(
                    "h2",
                    {
                      class: "facility-count",
                    },
                    [
                      h(
                        "span",
                        {
                          class: "facility-count",
                        },
                        [`${item.percentage_tx_new} % of Target`]
                      ),
                    ]
                  ),
                ]
              )
            );
          }
      ),
    };
  },
  render() {
    return <div id="foo">bar</div>;
  },
  methods: {
    textToHTML(str) {
      var dom = document.createElement("div");
      dom.innerHTML = str;
      return dom;
    },
  },
};
</script>

<style scoped>
.vue-carousel__item a {
  color: #ededed;
  text-decoration: none;
}
.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
}

.broadcast > .vue-feather {
  margin-right: 0.5rem;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding-top: 0px;
  margin-left: 0px;
}

.map-js-height {
  height: 275px !important;
}

.page-wrapper .page-body-wrapper .page-body {
  margin-top: 10px !important;
}

.chat-margin {
  margin-bottom: 10px !important;
}

.img-90 {
  width: 150px !important;
  height: 150px;
}

.rounded-square {
  border-radius: 5% !important;
}

.trasaction-sec .transaction-totalbal h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 350px;
  font-weight: 700;
}

.static-top-widget span {
  font-weight: 600;
  font-size: 45px;
}

.static-top-widget h4 {
  font-weight: 800;
  font-size: 40px;
}

.btn-arrow {
  font-size: 40px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: Uppercase;
  font-family: inherit;
  border-bottom: solid 4px #dedede;
  padding: 2px 0px 0px 0px;
}

.bottom-box {
  background-color: #97781d;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.facility-count,
.facility-count span {
  font-size: 2rem !important;
  font-weight: 800 !important;
}

.sticky {
  position: sticky;
  font-size: 1.5rem;
  padding: 20px;
  margin-top: -300px;
  width: 400px;
  margin-left: -200px;
}

.custom-padding {
  padding-right: 30px;
  padding-left: 20px;
}

.bg-danger {
  background-color: #d1ad41 !important;
  color: #fff;
}

.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  margin-left: 3px;
  margin-right: 3px;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.bottom-stats {
  background-color: #97781d !important;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.text-primary {
  color: #a7a7a7 !important;
}

.fa-2x {
    font-size: 6em;
    color: #b3b5bb26;
}
</style>