<template>
  <div class="home">    
    <iframe
      :src="'https://'+host+'/tx_curr.html'"
      frameborder="0"
      width="100%"
      height="1200"
      scrolling="auto"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {   
    return {
      host: location.host,
    };
  },
};
</script>



<style lang="scss" scoped>
.custom-height {
  height: 184.8px;
}

.bg-secondary1 {
  background-color: #124239 !important;
  color: #fff;
}

.bg-secondary2 {
  background-color: #56ad9c !important;
  color: #fff;
}

.bg-secondary3 {
  background-color: #5b958a !important;
  color: #fff;
}

.bg-green {
  background-color: #759f18 !important;
  color: #fff;
}
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
