<template>
  <div class="home">
    <div class="row col-xl-12">
      <div class="dashboard-title col-xl-10 box-col-6 des-xl-50">
        <h2 style="font-size: 50px">
          <i class="icofont icofont-chart-bar-graph"></i>HIV Testing Numbers in
          {{ this.$store.state.auth.user.data.facility.state }}
        </h2>
      </div>
      <div class="col-xl-2">
        <router-link :to="'/'">
          <button style="float: right" class="back-btn btn btn-default">
            Logout
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="row col-xl-12">
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <div class="card o-hidden border-0">
            <div class="bg-secondary b-r-4 card-body">
              <div class="media static-top-widget">
                <div class="media-body">
                  <span class="m-0">HTS_TST </span>
                  <h4 class="mb-0 counter">1035</h4>
                  <i class="icon-bg" data-feather="shopping-bag"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-3 col-lg-6">
          <div class="card o-hidden border-0">
            <div class="bg-primary2 b-r-4 card-body">
              <div class="media static-top-widget">
                <div class="media-body">
                  <span class="m-0">HTS_TST_POS </span>
                  <h4 class="mb-0">55</h4>
                  <i class="icon-bg" data-feather="message-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-xl-2 col-lg-6">
          <div class="card o-hidden border-0">
            <div class="bg-primary b-r-4 card-body">
              <div class="media static-top-widget">
                <div class="media-body">
                  <span class="m-0">TX_NEW </span>
                  <h4 class="mb-0">
                    {{
                      $filters.formatNumber(
                        this.$store.state.auth.user.data
                          .targetTXNewAchievmentByState.tx_new_achievement
                      )
                    }}
                  </h4>
                  <i class="icon-bg" data-feather="user-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-2 col-lg-6">
          <div class="card o-hidden border-0">
            <div class="bg-danger4 b-r-4 card-body">
              <div class="media static-top-widget">
                <div class="media-body">
                  <span class="m-0">Yield</span>
                  <h4 class="mb-0">5%</h4>
                  <i class="icon-bg" data-feather="user-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-2 col-lg-6">
          <div class="card o-hidden border-0">
            <div class="bg-warning2 b-r-4 card-body">
              <div class="media static-top-widget">
                <div class="media-body">
                  <span class="m-0">Linkage </span>
                  <h4 class="mb-0">34% </h4>
                  <i class="icon-bg" data-feather="user-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <iframe
      :src="'https://'+host+'/map.html'"
      frameborder="0"
      width="100%"
      height="1200"
      scrolling="auto"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {   
    return {
      host: location.host,
    };
  },
};
</script>



<style lang="scss" scoped>
.custom-height {
  height: 184.8px;
}

.bg-secondary1 {
  background-color: #124239 !important;
  color: #fff;
}

.bg-secondary2 {
  background-color: #56ad9c !important;
  color: #fff;
}

.bg-secondary3 {
  background-color: #5b958a !important;
  color: #fff;
}

.bg-green {
  background-color: #759f18 !important;
  color: #fff;
}
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
