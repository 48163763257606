<template>
  <div class="container-fluid general-widget">
    <div class="row">
      <div class="dashboard-title col-xl-10 box-col-6 des-xl-50">
        <h2 style="font-size: 50px">
          <i class="icofont icofont-chart-bar-graph"></i>Annual Net New target
          to Reach in {{ this.$store.state.auth.user.data.facility.state }}
          {{
            this.$store.state.auth.user.data.facility.state != "FCT"
              ? "State"
              : ""
          }}
        </h2>
      </div>
      <div class="col-xl-2">
        <router-link :to="'/'" >
        <button style="float: right" class="back-btn  btn btn-default">Logout</button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 box-col-6 des-xl-50">
        <div class="card trasaction-sec">
          <div class="card-header">
            <div class="header-top d-sm-flex align-items-center">
              <div class="setting-list">
                <ul class="list-unstyled setting-option">
                  <li>
                    <div class="setting-secondary">
                      <i class="icon-settings"> </i>
                    </div>
                  </li>
                  <li><i class="view-html fa fa-code font-secondary"></i></li>
                  <li>
                    <i
                      class="icofont icofont-maximize full-card font-secondary"
                    ></i>
                  </li>
                  <li>
                    <i
                      class="icofont icofont-minus minimize-card font-secondary"
                    ></i>
                  </li>
                  <li>
                    <i
                      class="icofont icofont-refresh reload-card font-secondary"
                    ></i>
                  </li>
                  <li>
                    <i
                      class="icofont icofont-error close-card font-secondary"
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="transaction-totalbal center-content">
            <h2>
              {{ this.numberToreach }}

              <span class="ms-3">
                <a class="btn-arrow arrow-secondary" href="javascript:void(0)"
                  >{{
                    this.$store.state.auth.user.data.numbersToReach.percentage
                  }}% of Target</a
                ></span
              >
            </h2>

            <span class="sticky bottom-box">
              Reporting
              {{ this.$store.state.auth.user.data.facility.facilities }}
              Facilities <br />Year ends in :
              {{
                this.$store.state.auth.user.data.facility.days_to_end_of_year
              }}
              days</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-xl-6 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">TX_CURR</span>
                <div class="row">
                  <div class="col-xl-6">
                    <h4 class="mb-0">
                      Target:<br />
                      {{
                        $filters.formatNumber(
                          this.$store.state.auth.user.data
                            .targetTXCurrAchievmentByState.target_tx_curr
                        )
                      }}
                    </h4>
                    <i class="icon-bg" data-feather="user-plus"></i>
                  </div>
                  <div class="col-xl-6">
                    <h4 class="mb-0">
                      Achievement: <br />
                      {{
                        $filters.formatNumber(
                          this.$store.state.auth.user.data
                            .targetTXCurrAchievmentByState.tx_curr_achievement
                        )
                      }}
                    </h4>
                    <i class="icon-bg" data-feather="user-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-6 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-secondary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">Yearly TX_New Target</span>
                <div class="row">
                  <div class="col-xl-6">
                    <h4 class="mb-0">
                      Target:<br />
                      {{
                        $filters.formatNumber(
                          this.$store.state.auth.user.data
                            .targetTXNewAchievmentByState.target_tx_new
                        )
                      }}
                    </h4>
                    <i class="icon-bg" data-feather="user-plus"></i>
                  </div>
                  <div class="col-xl-6">
                    <h4 class="mb-0">
                      Achievement:
                      {{
                        $filters.formatNumber(
                          this.$store.state.auth.user.data
                            .targetTXNewAchievmentByState.tx_new_achievement
                        )
                      }}
                    </h4>
                    <i class="icon-bg" data-feather="user-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row broadcast">
      <div class="col-xl-12">
        <h3 class="title">State TX_New Yearly Target Countdown by LGA</h3>
      </div>
      <div class="col-xl-12">
        <vue-carousel
          :data="data"
          :controls="false"
          :indicators="false"
          :interval="3000"
          direction="up"
        ></vue-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { h } from "vue";
//import {htmlToHs } from 'html-to-hyperscript';
// import VueFeather from 'vue-feather';
// VueFeather has been registered as a global component.

export default {
  name: "Dashboard",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  data() {
    this.targetAchievmentByLGA =
      this.$store.state.auth.user.data.targetAchievmentByLGA;
    this.numberToreach =
      this.$store.state.auth.user.data.numbersToReach.numbers_to_reach < 0
        ? "+" +
          Intl.NumberFormat().format(
            Math.abs(
              this.$store.state.auth.user.data.numbersToReach.numbers_to_reach
            )
          )
        : Intl.NumberFormat().format(
            Math.abs(
              this.$store.state.auth.user.data.numbersToReach.numbers_to_reach
            )
          );
    return {
      data: this.targetAchievmentByLGA.map(
        (item) =>
          function VueCarouselItem() {
            return h(
              "div",
              {
                class: "row",
              },
              h(
                "div", // type
                { class: "col-lg-6" }, // props

                [
                  h(
                    "h2",
                    {
                      class: "facility-count",
                      style: "border-bottom: 16px solid red",
                    },
                    [`${item.LGA}`]
                  ),
                ]
              ),
              h(
                "div", // type
                { class: "col-lg-3" }, // props

                [
                  h(
                    "h2",
                    {
                      style:
                        "background-color: #97781d !important;  color: #fff; font-weight: 700; display: flex; justify-content: center; font-size: 5rem",
                    },
                    [`${item.tx_new_achievement}`]
                  ),
                ]
              ),
              h(
                "div", // type
                { class: "col-lg-3" }, // props

                [
                  h(
                    "h2",
                    {
                      class: "facility-count",
                    },
                    [
                      h(
                        "span",
                        {
                          class: "facility-count",
                        },
                        [`${item.percentage_tx_new} % of Target`]
                      ),
                    ]
                  ),
                ]
              )
            );
          }
      ),
    };
  },
  render() {
    return <div id="foo">bar</div>;
  },
  methods: {
    textToHTML(str) {
      var dom = document.createElement("div");
      dom.innerHTML = str;
      return dom;
    },
  },
};
</script>

<style scoped>
.vue-carousel__item a {
  color: #ededed;
  text-decoration: none;
}
.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
}

.broadcast > .vue-feather {
  margin-right: 0.5rem;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding-top: 0px;
  margin-left: 0px;
}

.map-js-height {
  height: 275px !important;
}

.page-wrapper .page-body-wrapper .page-body {
  margin-top: 10px !important;
}

.chat-margin {
  margin-bottom: 10px !important;
}

.img-90 {
  width: 150px !important;
  height: 150px;
}

.rounded-square {
  border-radius: 5% !important;
}

.trasaction-sec .transaction-totalbal h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 350px;
  font-weight: 700;
}

.static-top-widget span {
  font-weight: 600;
  font-size: 45px;
}

.static-top-widget h4 {
  font-weight: 800;
  font-size: 40px;
}

.btn-arrow {
  font-size: 40px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: Uppercase;
  font-family: inherit;
  border-bottom: solid 4px #dedede;
  padding: 2px 0px 0px 0px;
}

.bottom-box {
  background-color: #97781d;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.facility-count,
.facility-count span {
  font-size: 2rem !important;
  font-weight: 800 !important;
}

.sticky {
  position: sticky;
  font-size: 1.5rem;
  padding: 20px;
  margin-top: -300px;
  width: 400px;
  margin-left: -200px;
}

.custom-padding {
  padding-right: 30px;
  padding-left: 20px;
}

.bg-danger {
  background-color: #d1ad41 !important;
  color: #fff;
}

.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  margin-left: 3px;
  margin-right: 3px;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.bottom-stats {
  background-color: #97781d !important;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}
</style>