<template>
  <div class="row col-xl-12">
     <div class="dashboard-title col-xl-10 box-col-6 des-xl-50">
    <h2 style="font-size: 50px">
      <i class="icofont icofont-chart-bar-graph"></i>Viral Load Cascase for
      {{ this.$store.state.auth.user.data.facility.state }}
    </h2>
     </div>
     <div class="col-xl-2">
        <router-link :to="'/'" >
        <button style="float: right" class="back-btn  btn btn-default">Logout</button>
        </router-link>
      </div>
  </div>
  <div class="row col-xl-12">
    <div class="col-sm-6 col-xl-3 col-lg-6">
      <div class="card o-hidden border-0 custom-height">
        <div class="bg-secondary1 b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">On ART </span>
              <h4 class="mb-0 counter">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.vlCascade.onart
                  )
                }}
              </h4>
              <i class="icon-bg" data-feather="shopping-bag"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-2 col-lg-6">
      <div class="card o-hidden border-0 custom-height">
        <div class="bg-primary b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">VL Eligible </span>
              <h4 class="mb-0">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.vlCascade.eligible
                  )
                }}
                <br />
                ({{
                  this.$store.state.auth.user.data.vlCascade
                    .percentage_eligible
                }}%)
              </h4>
              <i class="icon-bg" data-feather="message-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-3 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-secondary2 b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">Sample Collected</span>
              <h4 class="mb-0">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.vlCascade.sample_collected
                  )
                }}
                <br />
                ({{
                  this.$store.state.auth.user.data.vlCascade
                    .percentage_sample_collected
                }}%)
              </h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-2 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-green b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">VL Result</span>
              <h4 class="mb-0">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.vlCascade.vl_result
                  )
                }}
                ({{
                  this.$store.state.auth.user.data.vlCascade
                    .percentage_vl_result
                }}%)
              </h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-2 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-secondary3 b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0"> VL Supressed</span>
              <h4 class="mb-0">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.vlCascade.supressed
                  )
                }}
                ({{
                  this.$store.state.auth.user.data.vlCascade
                    .percentage_supressed
                }}%)
              </h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home">
    <vue-highcharts
      :options="chatData"
      ref="pvlsStateChart"
      style="min-width: 310px; height: 800px; margin: 0 auto"
    ></vue-highcharts>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import VueHighcharts from "vue3-highcharts";

export default {
  name: "Viralload",
  components: { VueHighcharts },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  setup() {},
  data() {
    this.vlData = this.$store.state.auth.user.data.vlCascadeChat;

    var tx_pvls_lga = [];
    var viral_load = [];
    var viral_load_suppressed = [];
    var percentage_viral_load_suppressed = [];

    this.vlData.forEach((value) => {
      tx_pvls_lga.push(value.LGA);
      viral_load.push(parseInt(value.vl_result));
      viral_load_suppressed.push(parseInt(value.supressed));
      percentage_viral_load_suppressed.push(
        parseFloat((value.supressed / value.vl_result).toFixed(2)) * 100
      );
    });

    return (this.chatData = this.build_bar_chart_dual_axis(
      "pvlsStateChart",
      null,
      "Number of Patients",
      "% Suppression",
      tx_pvls_lga,
      viral_load,
      "Viral Load Result",
      viral_load_suppressed,
      "Suppression",
      percentage_viral_load_suppressed,
      "% Suppression",
      false,
      "State"
    ));
  },
  methods: {
    build_bar_chart_dual_axis(
      container_id,
      title,
      y1_title,
      y2_title,
      xaxisCategory,
      parent_data,
      parent_data_name,
      child_data,
      child_data_name,
      percent_data,
      percent_data_name,
      useLine = true,
      xaxisTitle,
      height
    ) {
      //filter unneeded zero data

      var idxsToDelete = [];
      parent_data = (parent_data || []).filter((n, i) => {
        if (n > 0) {
          return true;
        }

        idxsToDelete.push(i);
        return false;
      });

      if (idxsToDelete.length > 0) {
        xaxisCategory = (xaxisCategory || []).filter(
          (n, i) => !idxsToDelete.includes(i)
        );
        child_data = (child_data || []).filter(
          (n, i) => !idxsToDelete.includes(i)
        );
        percent_data = (percent_data || []).filter(
          (n, i) => !idxsToDelete.includes(i)
        );
      }

      var series = [];

      //var hasThousand = false;

      if (parent_data.length > 0) {
        series.push({
          name: parent_data_name,
          type: "column",
          data: parent_data,
        });

        /*   hasThousand =
          hasThousand || parent_data.some((x) => Math.abs(x) >= 1000); */
      }

      if ((child_data || []).length > 0) {
        series.push({
          name: child_data_name,
          type: "column",
          data: child_data,
        });

        // hasThousand =
        //   hasThousand || child_data.some((x) => Math.abs(x) >= 1000);
      }

      var yAxis = [
        {
          // Secondary yAxis
          title: {
            //text: y1_title + (hasThousand ? " (thousands)" : ""),
            text: y1_title,
            rotation: 270,
          },
          labels: {
            formatter: function () {
              //return hasThousand ? parseInt(this.value) / 1000 : this.value;
              return Highcharts.numberFormat(this.value, 0, "", " ");
            },
            //format: '{value}'
          },
          tickmarkPlacement: "on",
          lineWidth: 0,
          gridLineWidth: 0,
          max: Math.max.apply(Math, parent_data),
          min: 0,
        },
      ];

      if ((percent_data || []).length > 0) {
        var percentSeries = {
          name: percent_data_name,
          type: "spline", //useLine ? 'spline' : 'scatter',
          data: percent_data,
          yAxis: 1,
          tooltip: {
            pointFormat: "<b>{point.y:.1f}%</b>",
          },
          marker: {
            radius: 5,
          },
        };

        if (!useLine) {
          percentSeries.lineWidth = 0;
          percentSeries.states = {
            hover: {
              lineWidth: 0,
              lineWidthPlus: 0,
              marker: {
                radius: 5,
              },
            },
          };
        }

        series.push(percentSeries);

        //add its axis
        yAxis.push({
          // Primary yAxis
          labels: {
            format: "{value}%",
            style: {
              color: "white",
              fontSize: "15px",
            },
          },
          tickmarkPlacement: "on",
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: y2_title,
          },
          opposite: true,
          max: 100,
          min: 0,
        });
      }

      return {
        chart: {
          zoomType: "xy",
          height: height,
        },
        title: {
          text: null,
          style: {
            fontSize: "12px",
          },
        },
        xAxis: [
          {
            categories: xaxisCategory,
            crosshair: true,
            style: {
              color: "white",
              fontSize: "20px",
            },
          },
        ],
        yAxis: yAxis,
        tooltip: {
          shared: true,
        },
        //colors: ['#615D8B', '#F88944', '#959335'],
        colors: ["#4E611C", "#BA6733", "#c72924"],
        legend: {
          enabled: true,
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FCFFC5",
          shadow: true,
        },
        exporting: { enabled: false },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-height {
  height: 184.8px;
}

.bg-secondary1 {
  background-color: #124239 !important;
  color: #fff;
}

.bg-secondary2 {
  background-color: #56ad9c !important;
  color: #fff;
}

.bg-secondary3 {
  background-color: #ba6733 !important;
  color: #fff;
}

.bg-green {
  background-color: #4e611c !important;
  color: #fff;
}
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.highcharts-xaxis-labels text {
  font-size: 20px !important;
  color: #fff !important;
  fill: #fff !important;
}
</style>
