<template>
  <div class="row col-xl-12">
    <div class="dashboard-title col-xl-10 box-col-6 des-xl-50">
      <h2 style="font-size: 50px">
        <i class="icofont icofont-chart-bar-graph"></i>HIV Testing Numbers in
        {{ this.$store.state.auth.user.data.facility.state }} (24th - 29th Sep 2021)
        (HTS) (1st 95)
      </h2>
    </div>
    <div class="col-xl-2">
        <router-link :to="'/'" >
        <button style="float: right" class="back-btn  btn btn-default">Logout</button>
        </router-link>
      </div>
  </div>
  <div class="row">
    <div class="row col-xl-12">
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-secondary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">HTS_TST </span>
                <h4 class="mb-0 counter">1035</h4>
                <i class="icon-bg" data-feather="shopping-bag"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary2 b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">HTS_TST_POS </span>
                <h4 class="mb-0">55</h4>
                <i class="icon-bg" data-feather="message-circle"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-2 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">TX_NEW </span>
                <h4 class="mb-0">164</h4>
                <i class="icon-bg" data-feather="user-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-2 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-danger4 b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">Yield</span>
                <h4 class="mb-0">5%</h4>
                <i class="icon-bg" data-feather="user-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-2 col-lg-6">
        <div class="card o-hidden border-0">
          <div class="bg-warning2 b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="media-body">
                <span class="m-0">Linkage </span>
                <h4 class="mb-0">34%</h4>
                <i class="icon-bg" data-feather="user-plus"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home">
    <Carousel
      :navigation="true"
      :pagination="true"
      :startAutoPlay="true"
      :timeout="5000"
      class="carousel"
      v-slot="{ currentSlide }"
      v-on:slideChanged="updateSlideChanged()"
    >
      <Slide v-for="(slide, index) in carouselSlides" :key="index">
        <div
          v-show="currentSlide === index + 1"
          class="slide-info"
          @v-show="navigationHandler"
        >
          <vue-highcharts
            :options="chatData"
            ref="barChart"
            style="min-width: 310px; height: 800px; margin: 0 auto"
          ></vue-highcharts>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import VueHighcharts from "vue3-highcharts";
import Carousel from "@/components/Carousel.vue";
import Slide from "@/components/Slide.vue";

var numberPerPage = 5;
var end = 0;
var current = 0;

export default {
  name: "Viralload",
  components: { Carousel, Slide, VueHighcharts },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  setup() {},
  data() {
    this.vlData = this.$store.state.auth.user.data.vlCascadeChat;
    this.carouselSlides = [];
    var totalSlides = Math.round(this.vlData.length / numberPerPage);
    for (var i = 0; i < totalSlides; i++) {
      this.carouselSlides.push(i);
    }
    this.carouselSlides;

    var vlPagedData = this.iterator(this.vlData, numberPerPage);
    return (this.chatData = this.plotChart(vlPagedData));
  },
  methods: {
    updateSlideChanged() {
      var vlPagedData = this.iterator(this.vlData, numberPerPage);
      return (this.chatData = this.plotChart(vlPagedData));
    },

    iterator(a, n) {
      var l = a.length;
      end = current + n;
      var part = a.slice(current, end);
      current = end < l ? end : 0;
      return part;
    },
    plotChart(vlPagedData) {
      var lga = [];
      var onart = [];
      var eligible = [];
      var Sample_collected = [];
      var vl_result = [];
      var supressed = [];
      var vlYield = [];

      vlPagedData.forEach((value) => {
        lga.push(value.LGA);
        onart.push(parseInt(value.onart));
        eligible.push(parseInt(value.eligible));
        Sample_collected.push(parseInt(value.Sample_collected));
        var resultObj = {
          result: parseInt(value.vl_result),
          color:
            value.vl_result > value.Sample_collected ? "#d22d3d" : "#759f18",
        };
        vl_result.push(resultObj);
        supressed.push(parseInt(value.supressed));
        vlYield.push(
          parseFloat((value.eligible / value.Sample_collected).toFixed(2)) *
            100000
        );
      });

      var max = onart.reduce(function (a, b) {
        return Math.max(a, b);
      }, -Infinity);
      return {
        chart: {
          events: {},
          backgroundColor: "#111727",
        },
        title: {
          text: "",
        },
        yAxis: {
          max: max,
          labels: {
            style: {
              color: "white",
              fontSize: "15px",
            },
          },
          tickmarkPlacement: "on",
          lineWidth: 0,
          gridLineWidth: 0,
        },

        xAxis: {
          categories: lga,
          labels: {
            style: {
              color: "white",
              fontSize: "20px",
            },
          },
        },
        labels: {
          color: "#FFF",
          backgroundColor: "#FCFFC5",
        },
        plotOptions: {
          series: {
            pointPadding: 0, // Defaults to 0.1
            groupPadding: 0.1, // Defaults to 0.2
          },
          line: {
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },

        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: 80,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FCFFC5",
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            type: "column",
            color: " #124239",
            legendColor: "black",
            name: "On ART",
            data: onart,
          },
          {
            type: "column",
            color: "#24695c",
            name: "VL Eligible",
            data: eligible,
          },
          {
            type: "column",
            color: "#56ad9c",
            name: "Sample Collected",
            data: Sample_collected,
          },
          {
            type: "column",
            name: "VL Result",
            data: vl_result.map((x) => ({
              y: x.result,
              color: x.color, // set color here dynamically
            })),
          },
          {
            type: "column",
            color: "#5b958a",
            name: "VL Supressed",
            data: supressed,
          },

          {
            type: "spline",
            name: "% Supression",
            data: vlYield,

            marker: {
              lineWidth: 0,
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: "#ba895d",
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-height {
  height: 184.8px;
}

.bg-secondary1 {
  background-color: #124239 !important;
  color: #fff;
}

.bg-secondary2 {
  background-color: #56ad9c !important;
  color: #fff;
}

.bg-secondary3 {
  background-color: #5b958a !important;
  color: #fff;
}

.bg-green {
  background-color: #759f18 !important;
  color: #fff;
}
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
