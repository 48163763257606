<template>
  <div>
    <div class="home">
      <Carousel
        :navigation="true"
        :pagination="false"
        :startAutoPlay="true"
        :timeout="20000"
        class="carousel"
        v-slot="{ currentSlide }"
        v-on:slideChanged="updateSlideChanged()"
      >
        <Slide :key="0">
          <div
            v-show="currentSlide === 0 + 1"
            class="slide-info"
            @v-show="navigationHandler"
          >
            <ViralloadMin />
          </div>
        </Slide>
        <Slide :key="1">
          <div
            v-show="currentSlide === 1 + 1"
            class="slide-info"
            @v-show="navigationHandler"
          >
            <Retention />
          </div>
        </Slide>       
      </Carousel>
    </div>
  </div>
</template>



<script>
import Retention from "../views/Retention.vue";
import ViralloadMin from "../views/ViralloadMin.vue";
import Carousel from "@/components/Carousel.vue";
import Slide from "@/components/Slide.vue";
export default {
  components: {
    Retention,
    ViralloadMin,
    Carousel,
    Slide,
  },
  data() {
    this.carouselSlides = [2];
  },
};
</script>

<style scoped>
.vue-carousel__item a {
  color: #ededed;
  text-decoration: none;
}
.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
}

.broadcast > .vue-feather {
  margin-right: 0.5rem;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  padding-top: 0px;
  margin-left: 0px;
}

.map-js-height {
  height: 275px !important;
}

.page-wrapper .page-body-wrapper .page-body {
  margin-top: 10px !important;
}

.chat-margin {
  margin-bottom: 10px !important;
}

.img-90 {
  width: 150px !important;
  height: 150px;
}

.rounded-square {
  border-radius: 5% !important;
}

.trasaction-sec .transaction-totalbal h2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 350px;
  font-weight: 700;
}

.static-top-widget span {
  font-weight: 600;
  font-size: 45px;
}

.static-top-widget h4 {
  font-weight: 800;
  font-size: 40px;
}

.btn-arrow {
  font-size: 40px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: Uppercase;
  font-family: inherit;
  border-bottom: solid 4px #dedede;
  padding: 2px 0px 0px 0px;
}

.bottom-box {
  background-color: #97781d;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.facility-count,
.facility-count span {
  font-size: 2rem !important;
  font-weight: 800 !important;
}

.sticky {
  position: sticky;
  font-size: 1.5rem;
  padding: 20px;
  margin-top: -300px;
  width: 400px;
  margin-left: -200px;
}

.custom-padding {
  padding-right: 30px;
  padding-left: 20px;
}

.bg-danger {
  background-color: #d1ad41 !important;
  color: #fff;
}

.broadcast {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  padding: 0.5rem 0.75rem;
  margin-left: 3px;
  margin-right: 3px;
}

.broadcast > .vue-carousel {
  flex: 1;
}

.broadcast-content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.example-slide {
  align-items: center;
  background-color: #666;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 10rem;
}

.bottom-stats {
  background-color: #97781d !important;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.text-primary {
  color: #a7a7a7 !important;
}

.fa-2x {
  font-size: 6em;
  color: #b3b5bb26;
}
</style>