<template>
  <div class="row col-xl-12">
    <div class="dashboard-title col-xl-10 box-col-6 des-xl-50">
      <h2 style="font-size: 50px">
        <i class="icofont icofont-chart-bar-graph"></i>Retention Analysis for
        {{ this.$store.state.auth.user.data.facility.state }}
        {{
          this.$store.state.auth.user.data.facility.state != "FCT"
            ? "State"
            : ""
        }}
      </h2>
    </div>
    <div class="col-xl-2">
         <router-link :to="'/'" >
        <button style="float: right" class="back-btn  btn btn-default">Logout</button>
        </router-link>
      </div>
  </div>
  <div class="row col-xl-12">
    <div class="col-sm-6 col-xl-4 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-primary b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">TX CURR</span>
              <h4 class="mb-0">
                 {{
                        $filters.formatNumber(
                          this.$store.state.auth.user.data
                            .targetTXCurrAchievmentByState.tx_curr_achievement
                        )
                      }}
              </h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-4 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-primary b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">RTT</span>
              <h4 class="mb-0">777</h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-xl-4 col-lg-6">
      <div class="card o-hidden border-0">
        <div class="bg-danger b-r-4 card-body">
          <div class="media static-top-widget">
            <div class="media-body">
              <span class="m-0">IIT</span>
              <h4 class="mb-0">
                {{
                  $filters.formatNumber(
                    this.$store.state.auth.user.data.retension.IIT_20Jan22
                  )
                }}
              </h4>
              <i class="icon-bg" data-feather="user-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home">
    <vue-highcharts
      :options="chatData"
      ref="waterfall"
      style="min-width: 310px; height: 800px; margin: 0 auto"
    ></vue-highcharts>
  </div>
</template>


<script>
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
more(Highcharts);
import VueHighcharts from "vue3-highcharts";

export default {
  name: "Retention",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  components: { VueHighcharts },
  setup() {},
  data() {
    return (this.chatData = {
      chart: {
        events: {},
        type: "waterfall",
        renderTo: "container",
        backgroundColor: "#111727",
      },

      title: {
        text: "",
      },
      /* series: [
        {
          dataLabels: {
            style: {
              fontSize: "26px",
              fontWeight: "normal",
            },
          },
        },
      ],
 */
      xAxis: {
        type: "category",
        labels: {
          style: {
            color: "white",
            fontSize: "20px",
          },
        },
      },

      yAxis: {
        title: {
          text: "Patients",
        },
        labels: {
          style: {
            color: "white",
            fontSize: "15px",
          },
        },
        tickmarkPlacement: "on",
        lineWidth: 0,
        gridLineWidth: 0,
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        pointFormat: "<b>{point.y:,.2f}</b> Patients",
      },

      series: [
        {
          upColor: Highcharts.getOptions().colors[2],
          color: Highcharts.getOptions().colors[3],
          data: [
            {
              color: "#2a7fc3",
              name: "Tx_Curr_FY21Q4",
              y: 173211,
            },
            {
              color: "#2a7fc3",
              name: "TX_New_FY22",
              y: 9072,
            },
            {
              name: "Transferred in FY22",
              color: "#2a7fc3",
              y: 18
,
            },
              {
              name: "RTT",
              color: "#2a7fc3",
              y: 777
,
            },
            {
              name: "Expected Tx_Curr",
              isIntermediateSum: true,
              color: "#9d9da3",
            },
            {
              name: "To 20Jan22",
              color: "#ed7d32",
              y: -375,
            },
            {
              name: "Death 20Jan22",
              color: "#ed7d32",
              y: -262,
            },
            {
              name: "ITT 20Jan22",
              color: "#ed7d32",
              y: -4693,
            },
            {
              name: "Tx_Curr_FY22Q1",
              isSum: true,
              color: "#2a7fc3",
            },
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return this.y;
            },
            style: {
              fontWeight: "bold",
            },
          },
          pointPadding: 0,
        },
      ],
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.carousel {
  position: relative;
  max-height: 100vh;
  height: 100vh;

  .slide-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;

    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
